<template>
    <div>
        <a-input-search
            placeholder="Поиск"
            v-model="searchText"
            allowClear
            size="large"
            class="mb-4"
            @change="search"/>
        <div 
            v-if="empty && !loading" 
            class="mt-5">
            <a-empty description="Нет данных" />
        </div>
        <UserCard 
            v-for="item in users.results" 
            :key="item.id" 
            :deleteUser="deleteUser"
            :leaveOrg="leaveOrg"
            :actions="actions"
            :org="org"
            :item="item" />
        <infinite-loading 
            ref="org_user_infinity"
            @infinite="getList"
            :identifier="infiniteId"
            v-bind:distance="10">
            <div 
                slot="spinner"
                class="flex items-center justify-center inf_spinner">
                <a-spin />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import UserCard from './UserCard.vue'
import debounce from '@/utils/lodash/debounce'

import eventBus from '@/utils/eventBus'
export default {
    components: {
        InfiniteLoading,
        UserCard
    },
    props: {
        org: {
            type: Object,
            required: true
        },
        minusUserCount: {
            type: Function,
            default: () => {}
        },
        updateTableRowsHeight: {
            type: Function,
            default: () => {}
        },
        actions: {
            type: Object,
            default: () => null
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        reloadMainList: {
            type: Function,
            default: () => {}
        },
        page_name: {
            type: String,
            default:'orgInfoDrawer'
        }
    },
    data() {
        return {
            loading: false,
            page: 0,
            empty: false,
            infiniteId: 'org_users_list',
            isScrolling: false,
            model: 'catalogs.ContractorModel',
            searchText: '',

            users: {
                results: [],
                next: true,
                count: 0
            }
        }
    },
    mounted() {
        eventBus.$on(`update_filter_${this.model}` ,()=> {
            this.reloadList()
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}`)
    },
    methods: {

        search: debounce(async function() {
            if(this.searchText.length > 1) {
                try {
                    this.reloadList()
                    // await this.getList()
                } catch(e) {

                } finally {
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                }
                
            } else{ 
                // await this.getList()
                this.reloadList()

            }
        },500),
        reloadList() {
            this.$nextTick(() => {
                this.page = 0
                this.empty = false
                this.users = {
                    results: [],
                    next: true,
                    count: 0
                }
                this.$refs['org_user_infinity'].stateChanger.reset()
            })
        },
        leaveOrg() {
            this.$confirm({
                title: 'Вы действительно хотите покинуть организацию?',
                okText: 'Покинуть',
                okType: 'danger',
                closable: true,
                maskClosable: true,
                cancelText: 'Отмена',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post(`/users/my_organizations/${this.org.id}/leave/`)
                            .then(() => {
                                this.$message.info('Вы успешно покинули организацию')
                                this.reloadMainList()
                                this.closeDrawer()
                                resolve(true)
                            })
                            .catch((error) => { 
                                this.$message.error('Ошибка')
                                reject(error)
                            })
                    })
                }
            })
        },
        deleteUser(record) {
            this.$confirm({
                title: 'Удалить пользователя из организации?',
                okText: 'Удалить',
                okType: 'danger',
                closable: true,
                maskClosable: true,
                cancelText: 'Отмена',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post(`/users/my_organizations/${this.org.id}/users/delete/`, {
                            id: record.id
                        })
                            .then(() => {
                                this.$message.info('Пользователь исключен из организации')
                                this.minusUserCount(this.org)
                                this.reloadList()
                                resolve(true)
                            })
                            .catch((error) => { 
                                this.$message.error('Ошибка удаления')
                                reject(error)
                            })
                    })
                }
            })
        },
        async getList($state) {
            if(!this.loading && this.users.next) {
                try {
                    this.loading = true
                    this.page += 1
                    const { data } = await this.$http.get(`/users/my_organizations/${this.org.id}/users/`, {
                        params: {
                            page: this.page,
                            page_size: 15,
                            page_name: this.page_name,
                            text: this.searchText

                        }
                    })

                    if(data) {
                        this.users.count = data.count
                        this.users.next = data.next
                    }

                    if(data?.results?.length)
                        this.users.results = this.users.results.concat(data.results)

                    if(this.page === 1 && !this.users.results.length) {
                        this.empty = true
                    }
                        
                    if(this.users.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            }
        },
    }
}
</script>